<template>
    <div :key="pageKey">
        <v-layout row class="pt-2">
            <v-flex xs6 lg3 md3 pl-5 py-1>{{ $t('message.nameAndBuyerAccess') }}</v-flex>
            <v-flex xs6 lg3 md4 py-1 class="ml-7">{{ module === 'shipping' ? $t('message.shippingUploadFileMaxSize') : $t('message.file') }}</v-flex>
        </v-layout>
        <template v-for="file in allFiles">
            <template v-if="file.Document.standard === true">
                <FileUpload
                    :file="file"
                    :module="module"
                    :file-name-prefix="fileNamePrefix"
                    :document="document(file.Document.doctype_id)"
                    :ref="'file_' + file.Document.doctype_id"
                    :update-mode="updateMode"
                    v-bind:key="file.Document.id"
                    @upload-file-done="addUploadedFile"
                    @delete-file-done="deleteFile"
                    @merge-setting-update-done="mergeSettingUpdated"
                />
            </template>
            <template v-if="file.Document.standard === false">
                <FileUpload
                    :file="file"
                    :module="module"
                    :file-name-prefix="fileNamePrefix"
                    :document="document(file.Document.doctype_id)"
                    :ref="'file_' + file.Document.doctype_id"
                    :update-mode="updateMode"
                    v-bind:key="file.Document.id"
                    v-if="document(file.Document.doctype_id) != null || justAddedNonStandardFiles.includes(file.Document.doctype_id) === true"
                    @upload-file-done="addUploadedFile"
                    @delete-file-done="deleteFile"
                    @merge-setting-update-done="mergeSettingUpdated"
                />
            </template>
        </template>
<!--        <FileUpload-->
<!--                :file="otherFile"-->
<!--                :module="module"-->
<!--                :file-name-prefix="fileNamePrefix"-->
<!--                :document="document(otherFile.Document.doctype_id)"-->
<!--                :update-mode="updateMode"-->
<!--                v-for="otherFile in otherFiles"-->
<!--                v-bind:key="otherFile.Document.id"-->
<!--                @upload-file-done="addUploadedFile"-->
<!--                @delete-file-done="deleteFile"-->
<!--                @merge-setting-update-done="mergeSettingUpdated"-->
<!--        />-->
        <v-layout row class="pt-2" v-if="selectOtherFiles.length > 0">
            <v-flex xs6 lg3 md3 pl-5 py-1>
                <span v-if="module === 'contract'">{{ $t('message.addContractDocument') }}</span>
                <span v-if="module === 'shipping'">{{ $t('message.addShippingDocument') }}</span>
                <span v-if="module === 'ncr'">{{ $t('message.addNcrDocument') }}</span>
                <span v-if="module === 'purchaseorder'">{{ $t('message.addPoDocument') }}</span>
            </v-flex>
            <v-flex xs6 lg3 md4 py-0>
                <v-select
                    :items="selectOtherFiles"
                    clearable
                    dense
                    hide-details
                    prepend-icon="null"
                    item-value="Document.doctype_id"
                    item-text="Document.title"
                    solo
                    v-model="otherFileId"
                ></v-select>
            </v-flex>
            <div class="d-flex flex-row align-center">
                <v-btn
                    color="default"
                    small
                    width="70px"
                    v-if="otherFileId != 999"
                    @click="addOtherFile"
                >{{ $t('message.add') }}
                </v-btn>
            </div>
        </v-layout>
        <v-layout class="pt-3" row v-if="otherFileId == 999">
            <v-flex xs6 lg3 md4 py-0 class="offset-xs-6 offset-md-3 offset-lg-2">
                <v-text-field
                    :rules="[...validationRules.plainText]"
                    :placeholder="$t('message.title')"
                    class="ml-8"
                    clearable
                    dense
                    hide-details="auto"
                    solo
                    @change="otherFileTitle = $event"
                />
                <v-checkbox
                    :true-value="1"
                    :label="$t('message.customerAccess')"
                    :value="1"
                    class="pt-0 my-2 ml-7"
                    hide-details="auto"
                    dense
                    v-model="otherFileCustomerAccess"
                />
                <v-btn
                    class="ml-8"
                    color="default"
                    small
                    @click="addOtherFile"
                >{{ $t('message.add') }}
                </v-btn>
            </v-flex>
        </v-layout>
    </div>
</template>

<script>
import FileUpload from "./FileUpload";
import {mapGetters} from "vuex";
import {mapFieldsPrefixed} from 'Helpers/vuex-map-fields-prefixed'
// import {log} from "Helpers/helpers";

export default {
    name: "FileUploadList",
    components: {FileUpload},
    props: ['fileNamePrefix','files', 'module','moduleId', 'updateMode','pageKey'],
    data() {
        return {
            allFiles: [],
            justAddedNonStandardFiles: [],
            otherFiles: [],
            otherFileId: null,
            otherFileCustomerAccess: 0,
            otherFileTitle: null,
            // pageKey: 0,
            user: null
        }
    },
    computed: {
        // allFiles() {
        //     if (this.files) {
        //         return this.files
        //     } else {
        //         return []
        //     }
        // },
        selectOtherFiles() {
            if (this.files) {
                let otherFiles = this.files.filter(file => file.Document.standard === false)
                otherFiles.sort((a,b) => {
                    return a.Document.title > b.Document.title ? 1 : -1
                })
                return otherFiles
            } else {
                return []
            }
        },
        standardFiles() {
            if (this.files) {
                return this.files.filter(file => file.Document.standard === true)
            } else {
                return []
            }
        },
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('contract', {
            Contract__documents: 'Contract.documents'
        }, 'statePrefix'),
        ...mapFieldsPrefixed('purchaseorder', {
            Purchaseorder__documents: 'Purchaseorder.documents'
        }, 'statePrefix'),
        ...mapFieldsPrefixed('shipment', {
            Shipment__documents: 'Shipment.documents'
        }, 'statePrefix'),
        ...mapFieldsPrefixed('epr', {
            Epr__documents: 'Epr.documents'
        }, 'statePrefix'),
        ...mapFieldsPrefixed('ncr', {
            Ncr__documents: 'Ncr.documents'
        }, 'statePrefix'),
        ...mapGetters([
            'validationRules'
        ])
    },
    methods: {
        addOtherFile() {
            if(this.otherFileId) {
                let obj = {}
                if (parseInt(this.otherFileId) === 999) {
                    let $n = 999
                    this.Shipment__documents.forEach(file => {
                        if (file.AssetDocument.doctype_id >= 999) {
                            $n = file.AssetDocument.doctype_id
                        }
                    })
                    obj = {
                        Document: {
                            doctype_id: parseInt($n) === 999 ? 999 : $n + 1,
                            customeraccess: this.otherFileCustomerAccess,
                            fieldname: 'others',
                            folder: 'others',
                            title: this.otherFileTitle,
                            standard: false
                        }
                    }
                    // console.log(obj)
                } else {
                    obj = this.selectOtherFiles.find((d) => d.Document.doctype_id === this.otherFileId)
                }

                // this.otherFiles.push(obj)

                const check = this.document( obj.Document.doctype_id)
                if(check === undefined) {
                    this.justAddedNonStandardFiles.push(obj.Document.doctype_id)
                    const check2 = this.allFiles.find(file => file.Document.doctype_id == (obj.Document.doctype_id))
                    if(check2 === undefined) {
                        this.allFiles.push(obj)
                    }
                    this.$nextTick(() => {
                        const el = this.$refs['file_' + obj.Document.doctype_id][0].$el
                        const previousElement = el.previousElementSibling
                        const previousTwoElements = previousElement.previousElementSibling
                        if(previousTwoElements) {
                            try {
                                previousTwoElements.scrollIntoView({behavior: 'smooth'})
                            } catch(er){
                                console.log('Cannot find element')
                            }
                        } else {
                            try {
                                previousElement.scrollIntoView({behavior: 'smooth'})
                            } catch(er){
                                console.log('Cannot find element')
                            }
                        }
                    })
                } else {
                    this.$toast.error(this.$t('message.errors.documentAlreadySelected'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                }

                this.otherFileId = null;
                this.otherFileCustomerAccess = null
                this.otherFileTitle = null;
            } else {
                this.$toast.error(this.$t('message.errors.noDocumentSelected'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        addUploadedFile: function (fileObject) {
            this.$emit('upload-file-done', fileObject)
        },
        deleteFile (assetDocumentId) {
            this.$emit('delete-file-done', assetDocumentId)
        },
        document(doctype_id) {
            if (this.module === 'contract') {
                return this.Contract__documents.find((d) => parseInt(d.AssetDocument.doctype_id) === parseInt(doctype_id))
            } else if (this.module === 'purchaseorder') {
                return this.Purchaseorder__documents.find((d) => parseInt(d.AssetDocument.doctype_id) === parseInt(doctype_id))
            } else if (this.module === 'shipping') {
                return this.Shipment__documents.find((d) => parseInt(d.AssetDocument.doctype_id) === parseInt(doctype_id))
            } else if (this.module === 'epr') {
                return this.Epr__documents.find((d) => parseInt(d.AssetDocument.doctype_id) === parseInt(doctype_id))
            } else if (this.module === 'ncr') {
                return this.Ncr__documents.find((d) => parseInt(d.AssetDocument.doctype_id) === parseInt(doctype_id))
            }
            return null
        },
        mergeSettingUpdated: function(){
            this.$emit('merge-setting-update-done')
        }
    },
    watch: {
        // Contract__documents (val){
        //    console.log(val)
        // }
    },
    created() {
        //check for other files
        this.justAddedNonStandardFiles = []
        this.allFiles = []
        this.allFiles = this.files
        if (this.module === 'contract') {
            this.Contract__documents.forEach(file => {
                let doctype_id = file.AssetDocument.doctype_id
                if(file.AssetDocument.doctype_id >= 999 && file.AssetDocument.doctype_id < 2000){ //guaranteed other file
                    let otherFile = {
                        Document: {
                            doctype_id: file.AssetDocument.doctype_id,
                            customeraccess: file.AssetDocument.customeraccess,
                            fieldname: 'others',
                            folder: 'others',
                            title: file.AssetDocument.title,
                            standard: false
                        }
                    }
                    // this.otherFiles.push(otherFile)
                } else {
                    let otherFile = this.selectOtherFiles.find((d) => d.Document.doctype_id == doctype_id)
                    let standard = otherFile?.Document?.standard
                    if (standard === false) {
                        // this.otherFiles.push(otherFile)
                    }
                }
            })
            this.Ncr__documents = [];
        } else if (this.module === 'purchaseorder') {
            this.Purchaseorder__documents.forEach(file => {
                let doctype_id = file.AssetDocument.doctype_id
                if(file.AssetDocument.doctype_id >= 999 && file.AssetDocument.doctype_id < 2000){ //guaranteed other file
                    let otherFile = {
                        Document: {
                            doctype_id: file.AssetDocument.doctype_id,
                            customeraccess: file.AssetDocument.customeraccess,
                            fieldname: 'others',
                            folder: 'others',
                            title: file.AssetDocument.title,
                            standard: false
                        }
                    }
                    // this.otherFiles.push(otherFile)
                } else {
                    let otherFile = this.selectOtherFiles.find((d) => parseInt(d.Document.doctype_id) === parseInt(doctype_id))
                    let standard = otherFile?.Document?.standard
                    if (standard === false) {
                        // this.otherFiles.push(otherFile)
                    }
                }
            })
            this.Ncr__documents = [];
        } else if (this.module === 'shipping') {
            this.Shipment__documents.forEach(file => {
                let doctype_id = file.AssetDocument.doctype_id
                if(file.AssetDocument.doctype_id >= 999 && file.AssetDocument.doctype_id < 2000){ //guaranteed other file
                    let otherFile = {
                        Document: {
                            doctype_id: file.AssetDocument.doctype_id,
                            customeraccess: file.AssetDocument.customeraccess,
                            fieldname: 'others',
                            folder: 'others',
                            title: file.AssetDocument.title,
                            standard: false
                        }
                    }
                    // this.otherFiles.push(otherFile)
                } else {
                    let otherFile = this.selectOtherFiles.find((d) => parseInt(d.Document.doctype_id) === parseInt(doctype_id))
                    let standard = otherFile?.Document?.standard
                    if (standard === false) {
                        // this.otherFiles.push(otherFile)
                    }
                }
            })
            this.Ncr__documents = [];
        } else if (this.module === 'ncr'){
            this.Ncr__documents.forEach(file => {
                let doctype_id = file.AssetDocument.doctype_id
                if(file.AssetDocument.doctype_id >= 999 && file.AssetDocument.doctype_id < 2000 ){ //guaranteed other file
                    let otherFile = {
                        Document: {
                            doctype_id: file.AssetDocument.doctype_id,
                            customeraccess: file.AssetDocument.customeraccess,
                            fieldname: 'others',
                            folder: 'others',
                            title: file.AssetDocument.title,
                            standard: false
                        }
                    }
                    // this.otherFiles.push(otherFile)
                } else {
                    let otherFile = this.selectOtherFiles.find((d) => parseInt(d.Document.doctype_id) === parseInt(doctype_id))
                    let standard = otherFile?.Document?.standard
                    if (standard === false) {
                        // this.otherFiles.push(otherFile)
                    }
                }
            })
        }

    },
    mounted() {
        // log(this.files)
        if(this.pageKey == null) this.pageKey = 0
    }
}
</script>

<style scoped>

</style>